import React from 'react'
// import PropTypes from 'prop-types'
// import { FormattedMessage } from 'react-intl'
import { Box } from 'rebass/styled-components'

import Layout from '../../layouts'
import SEO from '../../components/seo'
import Container from '../../components/container'
import { isBrowser } from '../../utils/isBrowser'

const OfficePage = (props) => {

  const openNetlifyLogin = () => {
    if (isBrowser && 'netlifyIdentity' in window) {
      window.netlifyIdentity.open()
    } else {
      console.info('No netlifyIdentity present in window')
    }
  }

  return (
    <Layout {...props} locale="en">
      <SEO title="Office" />

      <Box py={4}>
        <Container maxWidth="780px">
          <div
            onClick={openNetlifyLogin}
          >
            Login with Netlify Identity
          </div>
        </Container>
      </Box>
    </Layout>
  )
}

export default OfficePage
